<template>
  <ValidationProvider :rules="options.rules" :name="options.label" v-slot="{ errors }">
    <v-text-field
      v-model="model"
      :label="options.label"
      :hint="options.hint"
      :error-messages="errors"
      :placeholder="options.placeholder"
      :counter="options.counter"
    />
  </ValidationProvider>
</template>

<script>
export default {
  name: "field",
  props: ['value', 'options'],
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>

<style scoped>

</style>
